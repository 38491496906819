import React from "react";
import ShowModal from "./ShowModal";
import Cookie from "../../custom/js/Cookies";
import { assetURL } from "../../custom/js/func";
import CallIcon from "./CallIcon";

export default function CallFailed({
  message = "No Answer",
  onCall,
  onCancel,
}) {
  return (
    <ShowModal
      modalBodyClass=""
      id="call-failed"
      modalDialogClass="declined-screen"
    >
      <div className="call-items text-center CallDeclined">{message}</div>
      <div className="call-items text-center">
        <a className="cursor-pointer" onClick={onCall}>
          <CallIcon className={"icon-rounded"} />
          {/* <img src={assetURL("/call.png")} width={50} /> */}
          Call Again
        </a>
        <a
          className="cursor-pointer"
          onClick={onCancel}
          data-bs-dismiss="modal"
        >
          <CallIcon className={"icon-rounded"} icon="times" color="danger" />
          {/* <img src={assetURL("/end.png")} width={50} /> */}
          Exit
        </a>
      </div>
    </ShowModal>
  );
}
