import React from "react";
import ReactDOM from "react-dom/client";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

import { AppContextProvider } from "./video-chat/AppContextProvider";
// import Cart from "./ReactPortal/Cart";
// import ProductListing from "./ReactPortal/ProductListing";

// import VideoChatApp from "./video-chat/VideoChatApp";
import "./video-chat/css/style.css";
import "./video-chat/css/custom.css";
import AppPortal from "./video-chat/AppPortal";

// video-call-render
const appContainer = document.getElementById("video-call-container");

// window.appContainer = appContainer;

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: window.PUSHER_KEY || "2db3355ff525196a7b13",
  cluster: window.PUSHER_CLUSTER || "eu",
  forceTLS: true,
});

if (appContainer) {
  const root = ReactDOM.createRoot(appContainer);
  const dataAttributes = { ...appContainer.dataset };

  root.render(
    <React.StrictMode>
      <AppContextProvider {...dataAttributes}>
        <AppPortal data={dataAttributes} />
      </AppContextProvider>
    </React.StrictMode>
  );
}

// ReactDOM.render(
//   <CartProvider>
//     <Cart />
//     <ProductListing />
//   </CartProvider>,
//   document.getElementById("root")
// );
