import React from "react";
import ShowModal from "./ShowModal";
import {
  authUser,
  callBackgroundSelect as callBackgroundSelectModal,
  modalHide,
  processVirtualBackground,
  uploadCallBackgroundImage,
} from "../functions/custom";
import { useEffect } from "react";
import Cookie from "../../custom/js/Cookies";
import CallIcon, { CallButton } from "./CallIcon";
import { useState, useContext } from "react";
import AppContext from "../AppContextProvider";
import { removeVirtualBackground } from "../functions/video-connect";
import { Http } from "../../http";

export default function SelectCallBgImage({
  id = "callBgSelect",
  onCantinueCall,
}) {
  const {
    vbImgUploading,
    setVbImgUploading,
    VImages = [],
    setVImages,
    Pages,
    setPages,
  } = useContext(AppContext);
  const [VTBSaving, setVTBSaving] = useState(false);
  const [VTBReselect, setVTBReselect] = useState(false);
  const [BgImgDeleting, setBgImgDeleting] = useState(false);
  const [LoadingMore, setLoadingMore] = useState(false);
  const callBgImage = Cookie("bg-image").get();

  useEffect(() => {
    getSelectedVirtualBackground();
  }, []);

  function saveSelectedVirtualBackground(image) {
    Cookie("bg-image").set(image);
    setVTBReselect(true);
    setVTBSaving(true);
    Http.post("store-selected-virtual-background", {
      user_id: window.caller.id,
      image: image,
      // image: window.selectectVtBackground,
    })
      .then(() => {
        modalHide("#" + id);
        setVTBReselect(false);
      })
      .catch(console.error)
      .finally(() => setVTBSaving(false));
  }

  function getSelectedVirtualBackground() {
    Http.post("get-selected-virtual-background", {
      user_id: window.caller.id,
    })
      .then(({ data }) => {
        if (data.image) processVirtualBackground(data.image);
      })
      .catch(console.error);
  }

  function removeSelectedVirtualBackground() {
    Http.post("remove-virtual-background", {
      user_id: window.caller.id,
    })
      .then(() => {
        window.virtualProcessorEx = null;
        window.selectectVtBackground = null;
        window.bgImageUrl = null;
      })
      .catch(console.error);
  }
  const deleteVirtualBgImage = (itm) => () => {
    setBgImgDeleting(itm.id);

    Http.post("delete-uploaded-vt-background-image", itm)
      .then(() => setVImages(VImages.filter((_itm) => _itm?.id != itm?.id)))
      .catch(console.error)
      .finally(() => {
        setBgImgDeleting(false);
      });
  };

  const loadMoreVirtualImages = (url) => () => {
    setLoadingMore(true);

    Http.post(url, { user_id: authUser().id })
      .then(({ data }) => {
        setVImages(data.data);
        setPages(data.pages);
      })
      .catch(console.error)
      .finally(() => {
        setLoadingMore(false);
      });
  };

  return (
    <ShowModal
      modalContentClassName="border-none. "
      modalDialogClass="minwidth800"
      modalDialogStyle={{ maxWidth: 800 }}
      id={id}
      dismissable
    >
      <div class="row">
        <div class="col-lg-12">
          <h2 style={{ marginTop: 0 }}>Virtual Background Settings</h2>
        </div>
        <div class="col-lg-12">
          <form
            onChange={(e) => {
              modalHide("#" + id);
              setVTBReselect(true);
              setVbImgUploading(true);
              uploadCallBackgroundImage({
                e,
                setUploadStatus: setVbImgUploading,
                setVirtualBackgroundImages: setVImages,
                setPages,
              });
            }}
            className="form-group-mb-3 d-inline-block "
            style={{ marginRight: 15 }}
          >
            <strong style={{ paddingBottom: 10, display: "inline-block" }}>
              Select a Virtual Background (1280 pixels X 720 pixels)
            </strong>
            <br />
            <input
              type="file"
              class="form-control"
              name="bgimage"
              accept="image/jpg,image/png,image/jpeg"
            />
          </form>
        </div>
        <div class="col-lg-12" style={{ marginBottom: 10 }}>
          <br />
          <strong>Please select a virtual background</strong>
        </div>
        <div class="col-lg-12 gallery-img" style={{ marginBottom: 10 }}>
          <div class="d-flex-wrap  p-3">
            {/* {VImages.slice(0, 6).map((itm) => ( */}
            {VImages.map((itm) => (
              <div class="col-lg-4 col4 p-relative">
                {itm?.id && (
                  <CallButton
                    onClick={deleteVirtualBgImage(itm)}
                    icon="times"
                    color={"danger"}
                    iconClassName={"p-absolute delteVTBWrapper "}
                    children={
                      BgImgDeleting && BgImgDeleting == itm.id ? (
                        <SpinLoader text="" />
                      ) : undefined
                    }
                  />
                )}
                <div class="virtualimg">
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      // console.log(itm);
                      modalHide("#" + id);
                      setVTBReselect(true);

                      if (itm.image) {
                        processVirtualBackground(itm?.image);
                        saveSelectedVirtualBackground(itm.image);
                      }
                    }}
                  >
                    <img
                      src={itm?.image}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div class="col-lg-12 gallery-img" style={{ marginBottom: 30 }}>
          
        </div> */}
        <div class="col-lg-5">
          <CallButton
            onClick={() => {
              modalHide("#" + id);
              removeVirtualBackground();
              removeSelectedVirtualBackground();
            }}
            color="danger"
            iconClassName={"w100 text-center"}
          >
            No Virtual Background
          </CallButton>
        </div>
        <div class={`col-lg-7 ${VTBReselect ? "" : ".d-none"}`}>
          {LoadingMore ? (
            <div className="text-right">
              <SpinLoader className="spin-info" text="Loading..." />
            </div>
          ) : (
            <div className="text-right">
              {Pages.prev_page_url && (
                <CallButton
                  onClick={loadMoreVirtualImages(Pages.prev_page_url)}
                  style={{ marginRight: 10 }}
                >
                  <i className="fa fa-chevron-left" /> Prev
                </CallButton>
              )}
              {Pages.next_page_url && (
                <CallButton
                  onClick={loadMoreVirtualImages(Pages.next_page_url)}
                >
                  Next <i className="fa fa-chevron-right" />
                </CallButton>
              )}
            </div>
          )}
          {/* <CallButton
            onClick={saveSelectedVirtualBackground}
            iconClassName={"w100 text-center"}
          >
            {VTBSaving ? <SpinLoader text="Saving..." /> : "Save Settings"}
          </CallButton> */}
        </div>
      </div>
    </ShowModal>
  );
}

export function CallBackgroundSelect() {
  const { vbImgUploading, setVbImgUploading } = useContext(AppContext);
  return (
    <>
      <form
        onChange={uploadCallBackgroundImage}
        className="form-group-mb-3 d-inline-block "
        style={{ marginRight: 15 }}
      >
        <input
          type="file"
          style={{ opacity: 0, pointerEvents: "none", position: "absolute" }}
          name="bgimage"
          id="bgimage"
          accept="image/jpg,image/png,image/jpeg"
        />
        {/* <label for="bgimage" class="btn-w-100-btn-success"> 
      <CallIcon/>
      </label> */}
        {/* VImages */}
        <CallButton
          disabled={vbImgUploading}
          onClick={!vbImgUploading && callBackgroundSelectModal()}
          icon=" fa-solid fa-users-viewfinder"
          children={vbImgUploading ? <SpinLoader /> : "Virtual Background"}
        />
      </form>
    </>
  );
}

export function SpinLoader({ className = "", text = "Processing..." }) {
  return (
    <>
      <span class={"spin-loader " + className} /> {text}
    </>
  );
}

/*
 <form
        className="p-4"
        style={{
          maxWidth: 400,
          margin: "0 auto",
          paddingTop: 20,
          paddingBottom: 50,
        }}
        onChange={(e) => {
          // console.log("#" + id);
          modalHide("#" + id);

          setVbImgUploading(true);
          uploadCallBackgroundImage(e, null, setVbImgUploading);
        }}
        // onChange={(e) => uploadCallBackgroundImage(e, onCantinueCall)}
      >
        <div className="form-group mb-3">
          <input
            type="file"
            style={{ opacity: 0, pointerEvents: "none", position: "absolute" }}
            name="bgimage"
            id="bg-image"
            accept="image/jpg,image/png,image/jpeg"
          />
          <label for="bg-image" class="btn w-100 btn-success">
            Select Call Background
          </label>
        </div>
        {callBgImage && (
          <div className="form-group mb-3">
            <button
              type="button"
              onClick={() => {
                modalHide("#" + id);
                processVirtualBackground(callBgImage);
              }}
              // onClick={() => onCantinueCall(callBgImage)}
              class="btn w-100 btn-outline-primary. btn-outline-success"
            >
              Use Previous set Background
            </button>
          </div>
        )}
        {/* <div className="form-group mb-3">
          <button
            type="button"
            onClick={() => onCantinueCall(null)}
            class="btn w-100 btn-outline-success"
          >
            Continue without Background set
          </button>
        </div> *\/}
      </form>
*/
