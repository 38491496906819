import React from "react";
import ShowModal from "./ShowModal";

// import { VIDEO_CALL_USERS } from "../functions/video-connect";
import Cookie from "../../custom/js/Cookies";
import { assetURL } from "../../custom/js/func";
import CallIcon from "./CallIcon";
// import { callBackgroundSelect } from "../functions/custom";
import { CallBackgroundSelect } from "./SelectCallBgImage";

export default function AcceptedCall({
  caller,
  callee,
  onRejectCall,
  onAcceptCall,
}) {
  const id = "AcceptCall"; //,
  // { caller, callee } = VIDEO_CALL_USERS;

  return (
    <ShowModal id={id}>
      <div className="call-box incoming-box">
        <div className="call-wrapper">
          <div className="call-inner">
            <div className="call-user">
              <h4>Incoming call from {callee.name}...</h4>
              <div className="call-avatar">
                <img alt="User Image" src={callee.avatar} />
              </div>
            </div>
            <div className="call-items">
              <a
                href="#"
                // onClick={callBackgroundSelect("#acceptCallBgSelect")}
                onClick={onAcceptCall}
                data-toggle="modal"
                data-target={"#" + id}
              >
                <CallIcon children={"Accept"} />
                {/* <img src={assetURL("/call.png")} width={50} /> */}
                {/* <br /> */}
              </a>
              <CallBackgroundSelect />
              <a href="#" onClick={onRejectCall} data-bs-dismiss="modal">
                {/* <img src={assetURL("/end.png")} width={50} /> */}
                <CallIcon icon="times" color="danger" children={"Decline"} />
                {/* <br />  */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </ShowModal>
  );
}
