// CartContext.js
import React, { createContext, useState } from "react";
import {
  callFailed,
  modalOpen,
  modalClose,
  callUser,
  playSound,
  stopSoundPlaying,
  authWS,
  authUser,
} from "./functions/custom";
import { Http } from "../http";
import { connectVideo } from "./functions/video-connect";
import { useEffect } from "react";

const AppContext = createContext();

window.conn_channel = false;
window.$_room = null;
window.CallCancelled = false;
// let CallCancelled = false;
window.CallAccepted = false;
window.localTracks = null;
window.localAudioTrack = null;
window.localVideoTrack = null;
window.conn = {
  room: "",
  caller_token: "",
  callee_token: "",
  caller: {},
  callee: {},
  sid: "",
};

export function AppContextProvider({ caller, children }) {
  const [Users, setUsers] = useState({ caller: {}, callee: {} });
  const [ConnectedRoom, setConnectedRoom] = useState(null);
  const [CallCancelled, setCallCancelled] = useState(false);
  const [CallFailedMessage, setCallFailedMessage] = useState();
  const [vbImgUploading, setVbImgUploading] = useState(false);
  const [IsBlur, setIsBlur] = useState(false);

  const [VImages, setVImages] = useState([]);
  const [Pages, setPages] = useState({});
  // const [LocalVideoTrack, setLocalVideoTrack] = useState(null);
  const [VideoConnected, setVideoConnected] = useState(false);

  const _connectVideo = connectVideo(
    //funcs
    IsBlur,
    setConnectedRoom,
    setCallFailedMessage,
    setVideoConnected,
    endCall,
    onCancelCall,
    CallCancelled
  );

  useEffect(() => {
    if (window.conn_channel) return;

    const Echo = window.Echo,
      channel = Echo.channel("video-chat"),
      chat_declined = Echo.channel("video-chat-declined"),
      chat_cancelled = Echo.channel("video-chat-cancelled");

    chat_declined.listen(".call-rejected", function (data) {
      const { ConnectedRoom, $_room, conn } = window;

      if (typeof data.type !== "undefined") {
        switch (data.type) {
          case "call-failed":
            authWS(data, () => {
              console.log("Call failed!");
              window.CallCancelled = true;
              stopSoundPlaying();
              endCall($_room || ConnectedRoom);
              callFailed(data?.message, setCallFailedMessage);
            });
            break;
          case "close-other-call-tabs":
            if (!window.CallAccepted && data.userId == window.caller.id) {
              console.log("Call Answered on another tab!");
              stopSoundPlaying();
              modalClose();
            }
            break;
          case "call-declined":
            authWS(data, () => {
              console.log("Call rejected received!", { $_room, ConnectedRoom });
              stopSoundPlaying();
              window.CallCancelled = true;
              endCall($_room || ConnectedRoom);
              callFailed(data?.message, setCallFailedMessage);
            });
            break;
          default:
            break;
        }
      }
    });

    channel.listen(".process-video-call", function (data) {
      // console.log("Incoming call!");
      window.CallCancelled = false;
      authWS(data, (data, users) => {
        modalClose();
        playSound("ringingSound");
        // playSound("incomingSound");
        // console.log("call accepted!");

        if (typeof data.caller != "undefined") {
          const _caller_token = data.caller_token,
            _caller = data.caller,
            _callerId = data.callerId;

          data.caller_token = data.callee_token;
          data.callerId = data.calleeId;
          data.caller = data.callee;

          data.callee_token = _caller_token;
          data.calleeId = _callerId;
          data.callee = _caller;
        }
        window.conn = data;

        setUsers(data);
        modalOpen("#AcceptCall");
      });
    });
    window.conn_channel = true;
    getVirtualIimages();
  }, []);

  return (
    <AppContext.Provider
      value={{
        // conn,
        // $_room,
        IsBlur,
        setIsBlur,
        VImages,
        setVImages,
        vbImgUploading,
        setVbImgUploading,
        ConnectedRoom,
        setConnectedRoom,
        CallFailedMessage,
        setCallFailedMessage,
        VideoConnected,
        setVideoConnected,
        Users,
        setUsers,
        Pages,
        setPages,
        // func
        getVirtualIimages,
        generateAccessToken,
        onEndCall,
        endCall,
        onRejectCall,
        onCancelCall,
        onAcceptCall,
      }}
    >
      {children}
    </AppContext.Provider>
  );

  function getVirtualIimages() {
    Http.post("virtual-background-images", { user_id: window.caller.id })
      .then(({ data }) => {
        // console.log(data.images);
        setVImages(data.data);
        setPages(data.pages);
      })
      .catch(console.error);
  }

  function generateAccessToken(callee, callBackgroundImage) {
    window.CallCancelled = false;
    setCallCancelled(false);
    if (!callee) return;

    const caller = authUser();
    setUsers((s) => ({ ...s, callee, caller }));

    modalClose();
    setVideoConnected(false);
    modalOpen("#myModal2");

    playSound("ringingSound");

    window.callee = callee;
    window.caller = caller;
    // return;
    //console.log("window.conn", window.conn);
    const hasCallerToken = window.conn.caller_token;
    if (!hasCallerToken) {
      //   alert("You need to create user and add another user to call!");

      Http.post(
        "video/generate-token",
        {
          caller,
          callee,
        }

        //, {
        //   mode: "no-cors",
        //   headers: {
        //     "Access-Control-Allow-Origin": "*",
        //     "Content-Type": "application/json",
        //   },
        //   credentials: "same-origin",
        // }
      )
        .then(({ data }) => {
          window.conn = data;
          console.log("window.CallCancelled", data);
          if (!CallCancelled || !hasCallerToken)
            _connectVideo(data, true, callBackgroundImage);
        })
        .catch(() => {
          callFailed("Call Failed", setCallFailedMessage);
          stopSoundPlaying();
        });
    } else {
      callUser(window.conn, function (data) {
        if (!CallCancelled)
          _connectVideo(window.conn, true, callBackgroundImage);
      });
    }
  }

  function onEndCall() {
    window.CallCancelled = true;
    window.bgImageUrl = null;
    setCallCancelled(true);
    console.log("CallCancelled!!!", window.CallCancelled);
    endCall();
    onCancelCall();
    if (window.clrtimeout) clearTimeout(window.clrtimeout);
  }

  function endCall(room = ConnectedRoom) {
    // console.log("call aborted!!!");
    stopSoundPlaying();
    modalClose();

    if (!room) return;
    room.disconnect();
    if (room.localParticipant) {
      room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable();
      });

      room.localParticipant.videoTracks.forEach((publication) => {
        if (publication && publication.track) {
          publication.track.disable();
          publication.track.stop();
          publication.unpublish();

          const element = publication.track.detach();
          console.log("Participant disconnected!", element);
          if (element) element.srcObject = null;
        }
      });
    }
  }

  function closeOtherCallTabs(closeModal = true) {
    Http.post("call-rejected", {
      data: {
        userId: window.caller.id,
        message: "Call accepted on another tab",
        type: "close-other-call-tabs",
      },
    })
      .then(({ data }) => {
        console.log("xhr to close other call tabs sent: ");
      })
      .catch(() => {});
  }

  function onRejectCall(closeModal = true) {
    stopSoundPlaying();
    modalClose();
    Http.post("call-rejected", {
      data: { ...window.conn, message: "Call Declined", type: "call-declined" },
    })
      .then(({ data }) => {
        console.log("Declined: ");
      })
      .catch(() => {});
  }

  function onCancelCall() {
    Http.post("call-rejected", {
      // Http.post("video-call-cancelled", {
      data: { ...window.conn, message: "Call Ended", type: "call-failed" },
      // data: { ...window.conn, message: "Call Cancelled", type: "call-failed" },
    })
      .then(({ data }) => {
        console.log("cancelled: ", data);
      })
      .catch(() => {});
  }

  function onAcceptCall(bgImage) {
    closeOtherCallTabs();
    stopSoundPlaying();
    setVideoConnected(true);
    window.CallAccepted = true;

    _connectVideo({ ...window.conn }, false, bgImage);
    modalOpen("#myModal2");
  }
}

export default AppContext;

/*

Add processor error: Error: A VideoProcessor has already been added.
    at t.addProcessor (videotrack.js:271:13)
    at t.addProcessor (localvideotrack.js:123:39)
    at video-connect.js:284:17
    at Array.forEach (<anonymous>)
    at $t (video-connect.js:281:17)
    at connectVidCallback (custom.js:57:9)
    at video-connect.js:325:43
    at d (regeneratorRuntime.js:44:17)
    at Generator.<anonymous> (regeneratorRuntime.js:125:22)
    at Generator.next (regeneratorRuntime.js:69:21)
*/
