import React from "react";
import ReactDOM from "react-dom";
import { useEffect } from "react";

import Cookie from "../custom/js/Cookies";
import CallButton from "./VideoCallButton";
import VideoCallContainer from "./VideoCallContainer";

export default function AppPortal({ data }) {
  const caller = data?.caller;
  const elements = [
    {
      id: "video-call-button",
      component: CallButton,
    },
    {
      component: VideoCallContainer,
    },
  ];

  useEffect(() => {
    window.assetUrl = data?.assetUrl;
    window.bgAssetUrl = data?.bgAssetUrl;

    Cookie("asset_url").set(data?.assetUrl);
    let _caller = caller;
    if (typeof caller !== "string") {
      _caller = JSON.stringify(caller);
    }
    Cookie("caller").set(_caller, 2034);
  }, []);

  try {
    data.caller = JSON.parse(caller);
    window.caller = data.caller;
  } catch (e) {
    window.caller = caller;
  }

  return (
    <>
      {elements.map((item) => {
        if (!item.id && typeof item.component == "function")
          return <item.component {...data} />;

        const container = document.getElementById(item.id);

        if (!container) return null;

        const dataAttributes = { ...container.dataset };

        return ReactDOM.createPortal(
          <item.component {...data} {...dataAttributes} />,
          container
        );
      })}
    </>
  );
}
