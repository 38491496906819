import React from "react";

export default function CallIcon({
  color = "",
  icon = "phone",
  className,
  children,
}) {
  return (
    <span className={`icon-video bg-${color} ${className}`}>
      {children ? children : <i className={`fa fa-${icon}`} />}
    </span>
  );
}

export function CallButton({
  onClick,
  color = "",
  icon = "phone",
  className,
  iconClassName,
  children,
  ...otherProps
}) {
  return (
    <a
      {...otherProps}
      href="javascript:void(0)"
      onClick={onClick}
      className={className}
    >
      <CallIcon
        children={children}
        icon={icon}
        color={color}
        className={iconClassName}
      />
    </a>
  );
}
