import { Pipeline, VirtualBackgroundProcessor } from "@twilio/video-processors";
import Cookie from "../../custom/js/Cookies";
import { assetURL } from "../../custom/js/func";
import { Http } from "../../http";
import {
  setCallBackgroundImage,
  subscribeVideoBgTracks,
} from "./video-connect";
// import { VIDEO_CALL_USERS } from "../functions/video-connect";

export const options = {
  audio: true,
  video: { height: 720, frameRate: 24, width: 820 },
};

export function srcObject(videoElement, mediaStreamTrack) {
  if ("srcObject" in videoElement) {
    videoElement.srcObject = mediaStreamTrack;
  } else {
    // For older browsers, use the deprecated 'src' property with a URL created from the track
    videoElement.src = URL.createObjectURL(mediaStreamTrack);
  }
}

export function callFailed(message, setCallFailedMessage) {
  modalBackdropRemove();
  modalClose();

  setCallFailedMessage(message);
  setTimeout(() => {
    modalOpen("#call-failed");
  }, 500);
}

export function uploadCallBackgroundImage({
  e,
  onSuccess,
  setUploadStatus,
  setVirtualBackgroundImages,
  setPages,
}) {
  const { currentTarget: form } = e;
  const formData = new FormData(form);
  formData.append("id", window.caller.id);

  Http.post("call-bg-upload", formData, { ContentType: "multipart/form-data" })
    .then(({ data }) => {
      const bg_url = data.bg_url;
      processVirtualBackground(bg_url);
      Cookie("bg-image").set(bg_url);

      if (isFunction(setVirtualBackgroundImages) && data?.data)
        setVirtualBackgroundImages(data.data);

      if (isFunction(setPages) && data?.pages) setPages(data.pages);
      // setVirtualBackgroundImages((s) => [data.image, ...s]);

      if (isFunction(onSuccess) && data?.image) {
        onSuccess(data.image);
      }
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      if (setUploadStatus) {
        setUploadStatus(false);
      }
      if (e?.target?.value) e.target.value = "";
    });
}

export function processVirtualBackground(bg_url) {
  window.selectectVtBackground = bg_url;
  if (window.$_room)
    setCallBackgroundImage({
      bgImageUrl: bg_url,
      connectVidCallback: (callBackground) =>
        subscribeVideoBgTracks(window.localTracks, callBackground),
    });
  else window.bgImageUrl = bg_url;
}

export function isFunction(callback) {
  if (typeof callback == "function" || callback instanceof Function)
    return true;
  return false;
}

export function modalOpen(id = "#call-failed") {
  window.$(id).modal("show");
  window.$(".modal-dialog").draggable({
    handle: ".modal-content",
  });
}

export function modalBackdropRemove() {
  if (window.$(".modal-backdrop").length > 0)
    window.$(".modal-backdrop").css({ display: "none" }).remove();
}

export function modalClose() {
  disconnectVideoCamera();
  modalBackdropRemove();
  window.$(".modal").modal("hide");
}

export function disconnectVideoCamera() {
  try {
    const localTracks = window.localTracks;
    // Get the LocalVideoTrack from the LocalParticipant
    if (window.$_room) window.$_room.disconnect();
    if (window.localVideoTrack) {
      window.localVideoTrack.disable();
      window.localVideoTrack.stop();
      console.log(window.localVideoTrack);
    }

    if (!localTracks) return null;
    localTracks.forEach((track) => {
      console.log({ track });
      if (track.kind === "video") {
        track.disable();
        track.stop();
      }
    });
    console.log("Video track disabled >");
  } catch (e) {
    console.log("DisconnectVideoCamera()", e);
  }
}
export function modalHide(id = ".modal") {
  window.$(id).modal("hide");
}

export function callUser(conn, callback) {
  Http.post("call-user", { data: conn })
    .then(callback)
    .catch(() => {});
}

export function authUser() {
  let _caller;

  if (window.caller) _caller = window.caller;
  else _caller = Cookie("caller").get();
  if (typeof _caller == "string") {
    try {
      return JSON.parse(_caller);
    } catch (e) {
      return {};
    }
  }
  return _caller;
}

export function authId() {
  const users = authUser(),
    uid = trim_r(users?.id + " " + users?.name);

  return uid;
}
export function trim_r($val) {
  return String($val).replace(/\W/gi, "-").toLowerCase();
}
export const callBackgroundSelect =
  (id = "#callBgSelect") =>
  () => {
    if (window.videoNotAvailable) {
      alert(
        "Cannot use virtual background: Video camera in use or unavailable."
      );
      return false;
    }
    // console.log(id);
    modalOpen(id);
  };

export function isCallee(data) {
  return typeof data.calleeId != "undefined" && data.calleeId === authId();
}

export function authWS(data, callback) {
  try {
    // const users = VIDEO_CALL_USERS;

    if (isCallee(data)) {
      if (typeof callback == "function") {
        callback(data);
      }
    }
  } catch (e) {
    console.error(e);
  }
}

/***
 * id for #incomingSound
 * */
export function playSound(id = "ringingSound", ringing = false) {
  try {
    stopSoundPlaying();

    const audio = document.getElementById(id);

    if (!audio) return;
    if (ringing) {
      // audio.src = assetURL("/phone-outgoing-call.mp3");
      audio.src = assetURL("/v-ringing-tone.mp3");
      audio.play();
    } else audio.play();
  } catch (e) {
    console.error("Audio ringing error:", e);
  }
}

export function stopSoundPlaying() {
  stopSound("incomingSound");
  stopSound("ringingSound");
}
/***
 * id for #incomingSound
 * */
export function stopSound(id = "ringingSound") {
  try {
    const audio = document.getElementById(id);
    // const audio = document.querySelector("audio");

    if (!audio) return;
    if (audio.duration > 0 || !audio.paused) {
      audio.pause();
      audio.currentTime = 0;
    }
  } catch (e) {
    console.error("Stop audio ringing error:", e);
  }
}
