import Cookie from "./Cookies";

export function removeSpaces(str = "") {
  return String(str).replace(/\s+/gi, "");
}
export function smoothScrollToBottom(element, scrollIncrement = 100) {
  var currentPosition = element.scrollTop;
  var targetPosition = element.scrollHeight - element.clientHeight;
  // var scrollIncrement = 20;
  // var scrollIncrement = 100;

  function scroll() {
    currentPosition += scrollIncrement;
    if (currentPosition > targetPosition) {
      currentPosition = targetPosition;
    }
    element.scrollTop = currentPosition;
    if (currentPosition < targetPosition) {
      window.requestAnimationFrame(scroll);
    }
  }

  scroll();
}
export function objectEntries(object) {
  if (typeof object !== "object") new Error("value not an Object");
  const ob = [];
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const value = object[key];
      ob.push([value, key]);
    }
  }
  return ob;
}

export function assetURL(path = "") {
  const assetUrl = Cookie("asset_url").get();
  return assetUrl + path;
}
