import React from "react";
import ShowModal from "./ShowModal";
import { formToJSON } from "axios";
import Cookie from "../../custom/js/Cookies";

export default function AddNewUser({ onAddUser_ }) {
  function onAddUser(e) {
    e.preventDefault();

    const form = formToJSON(e.target);
    form.callee = String(form.callee).toLowerCase();
    form.caller = String(form.caller).toLowerCase();

    Cookie("users").set(JSON.stringify(form));
    window.$(".modal").modal("hide");
    window.location.reload();
  }

  return (
    <ShowModal id="add_user">
      <form onSubmit={onAddUser}>
        <div>Add new User</div>
        <div class="form-group">
          <label for="">Enter Your Name</label>
          <input
            type="text"
            name="caller"
            class="form-control"
            placeholder="caller"
          />
        </div>
        <div class="form-group">
          <label for="">Enter Name other User</label>
          <input
            type="text"
            name="callee"
            class="form-control"
            placeholder="callee"
          />
        </div>
        <button class="btn btn-primary btn-sm">Add User</button>
      </form>
    </ShowModal>
  );
}
