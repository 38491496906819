import React from "react";
import { useContext } from "react";
import AppContext from "./AppContextProvider";
import Cookie from "../custom/js/Cookies";
import { callBackgroundSelect, modalOpen } from "./functions/custom";

export default function VideoCallButton({ callee, callBtnLabel }) {
  const { generateAccessToken } = useContext(AppContext);

  try {
    Cookie("callee").set(callee, 2034);
    callee = JSON.parse(callee);
    window.callee = callee;
  } catch (e) {
    window.callee = callee;
  }

  return (
    <div>
      {/* <button onClick={callBackgroundSelect()}>
        {callBtnLabel || "Video Call"}
      </button> */}
      <button onClick={() => generateAccessToken(callee)}>
        {callBtnLabel || "Video Call"}
      </button>
    </div>
  );
}
