import Axios from "axios";
import Cookie from "../../custom/js/Cookies";
import { assetURL } from "../../custom/js/func";
import { Http } from "../../http";
import {
  callFailed,
  modalClose,
  playSound,
  stopSoundPlaying,
  options,
  //
  srcObject,
  isFunction,
  disconnectVideoCamera,
} from "./custom";

const { connect, createLocalTracks } = require("twilio-video"),
  {
    VirtualBackgroundProcessor,
    Pipeline,
    GaussianBlurBackgroundProcessor,

    isSupported,
  } = require("@twilio/video-processors"),
  _jwt = { room: "", caller_token: "", sid: "" };

export const connectVideo =
  (
    IsBlur,
    setConnectedRoom,
    setCallFailedMessage,
    setVideoConnected,
    endCall,
    onCancelCall,
    CallCancelled
  ) =>
  async (jwt = _jwt, ringing = false) => {
    disconnectVideoCamera();
    try {
      window.videoNotAvailable = false;
      const bg_url =
        Cookie("bg-image").get() ||
        window.bgImageUrl ||
        window.selectectVtBackground;
      if (bg_url) {
        setCallBackgroundImage({
          bgImageUrl: bg_url,
          connectVidCallback: (callBackground) => connectVid(callBackground),
        });
      } else connectVid();

      async function connectVid(callBackground) {
        const clrtimeout = setTimeout(() => {
          if (!window.CallAccepted && ringing) {
            console.log("video call cancelled after no answer.");
            sendMissedCall().catch(() => {
              disconnectCall();
            });
            disconnectCall();
            callFailed("No Answer", setCallFailedMessage);
          }
          // }, 5000);
        }, 65000);

        window.clrtimeout = clrtimeout;
        // }, 180000 * 2);
        try {
          //createLocalAudioTrack()
          const localTracks = (window.localTracks = await createLocalTracks(
            options
          ));
          console.log("Connecting...");

          connect(jwt.caller_token, {
            name: jwt.sid,
            maxAudioBitrate: 16000,
            tracks: window.localTracks,
            ...options,
          }).then(
            (room) => {
              // setVideoConnected(true);
              // if (ringing) playSound(undefined, ringing);
              setConnectedRoom(room);
              window.$_room = room;

              // room.localParticipant.publishTracks();
              // Log your Client's LocalParticipant in the Room
              const localParticipant = room.localParticipant;

              console.log(
                `Connected to Room as: "${localParticipant.identity}"`
              );

              const videoChatWindow = window.$("#video-display-2"),
                videoChatWindow_1 = window.$("#video-display-1");
              // localTracks.
              localTracks.forEach((track) => {
                if (callBackground)
                  subscribeVideoBgTracks(localTracks, callBackground);
                if (IsBlur)
                  callBackgroundBlurEffectLoad(
                    window.setCallBlurBackgroundOnLoad
                  );

                if (track.kind === "video") {
                  videoChatWindow.html(track.attach());
                  videoChatWindow
                    .find("video")
                    .css({ width: "100%", height: "100%" });

                  window.localVideoTrack = track;
                }
                if (track.kind === "audio") {
                  window.localAudioTrack = track;
                }
              });

              const _streamMedia = streamMedia();

              room.on("trackSubscribed", (track) => {
                setVideoConnected(true);
                // console.log("trackSubscribed");
                stopSoundPlaying();
                clearTimeout(clrtimeout);
                window.CallAccepted = true;

                _streamMedia(track, videoChatWindow_1);
              });

              room.on("participantConnected", (participant) => {
                setVideoConnected(true);
                stopSoundPlaying();
                clearTimeout(clrtimeout);
                window.CallAccepted = true;

                console.log(
                  `A Participant "${participant.identity}" has connected to the Room`
                );

                // room.localParticipant.videoTracks.forEach((publication) => {
                //   publication.track.enable();
                // });

                participant.tracks.forEach((publication) => {
                  if (publication.isSubscribed) {
                    const track = publication.track;

                    _streamMedia(track, videoChatWindow_1);

                    // videoChatWindow_1.html(track.attach());
                  }
                });
              });

              room.on("disconnected", function (room) {
                stopSoundPlaying("ringingSound");
                // clearTimeout(clrtimeout);

                disconnectLocalTracks();

                setVideoConnected(false);

                if (videoChatWindow.length > 0) {
                  videoChatWindow.find("video")[0].srcObject = null;
                  videoChatWindow.html("");
                }

                if (!window.CallCancelled) {
                  onCancelCall();
                  if (window.CallAccepted)
                    callFailed("Call Ended", setCallFailedMessage);
                }

                window.conn = {};

                console.log("Disconnected from Room:", room.name);
                window.CallAccepted = false;
                window.bgImageUrl = null;
              });

              // Log Participants as they disconnect from the Room
              room.on("participantDisconnected", (participant) => {
                window.CallAccepted = false;
                window.bgImageUrl = null;
                // clearTimeout(clrtimeout);
                stopSoundPlaying("incomingSound");
                callFailed("Call Ended", setCallFailedMessage);

                window.conn = {};

                disconnectLocalTracks();

                participant.videoTracks.forEach((publication) => {
                  if (publication.track) {
                    publication.track.disable();
                    publication.unpublish();
                    publication.track.stop();
                  }
                });
                participant.audioTracks.forEach((publication) => {
                  publication.track.disable();
                });
                endCall(window.$_room);
                console.log(
                  `A Participant "${participant.identity}" has disconnected from the Room`
                );
              });
              room.on("trackUnpublished", function (publication) {
                videoUnpublish();
                /* Hide the associated <video> element and show an avatar image. */
              });
              room.on("trackUnsubscribed", function (publication) {
                videoUnpublish();
                /* Hide the associated <video> element and show an avatar image. */
              });
              if (window.CallCancelled) {
                console.log("CallCancelled:", window.CallCancelled);
                stopSoundPlaying();
                disconnectCall();
                room.disconnect();

                // disconnectLocalTracks();
              }
            },
            (error) => {
              callProcessFailed();
              console.error(`Unable to connect to Room: ${error.message}`);
            }
          );
        } catch (e) {
          const errorMessage =
            "Error: Your camera is already in use. Close other camera apps or browser tab using the camera and try again.";
          // const _errorMessage =
          //   "Error: Your camera is already in use by another application or browser tab. Please close any other applications or tabs using the camera and try again.";

          alert(errorMessage);
          window.videoNotAvailable = true;
          // clearTimeout(window.clrtimeout);
          console.log("LocalTracks Error: ", e);
          // alert(_errorMessage);
        }
      }
    } catch (error) {
      callProcessFailed();
      console.error("callProcessFailed: ", error);
    }
    function disconnectCall() {
      // modalClose();
      stopSoundPlaying();

      endCall(window.$_room);
      onCancelCall();
    }

    function callProcessFailed() {
      window.CallAccepted = false;
      onCancelCall();
      endCall(window.$_room);
      callFailed("Call Failed", setCallFailedMessage);
      window.conn = {};
    }
    function disconnectLocalTracks() {
      if (window.$_room) window.$_room.disconnect();

      if (window.localAudioTrack) {
        window.localAudioTrack.disable();
        window.localAudioTrack.stop();
      }

      if (window.localVideoTrack) {
        window.localVideoTrack.disable();
        window.localVideoTrack.stop();
      }
      window.callVirtualBackground = null;
      // window.callBackgroundBlurEffectLoad = null;
      window.callBlurBackground = null;
      // console.log("disconnectLocalTracks:!!", window.$_room);
    }
  };

function streamMedia() {
  let vTrack, aTrack;
  return (track, videoWrapperElement) => {
    const videoElement = document.createElement("video");
    if (track.kind === "video") {
      vTrack = track.mediaStreamTrack;
    }
    if (track.kind === "audio") {
      aTrack = track.mediaStreamTrack;
    }

    if (vTrack && aTrack) {
      const mstream = new MediaStream([vTrack, aTrack]);
      videoElement.autoplay = true;
      srcObject(videoElement, mstream);
      videoWrapperElement.html(videoElement);
      videoWrapperElement.find("video").css({ width: "100%", height: "100%" });
    }
  };
}
function videoUnpublish() {
  const videoChatWindow_1 = window.$("#video-display-1");
  const img = document.createElement("img");
  img.src = assetURL("/video-dishplay.png");
  videoChatWindow_1.find("video").css({ display: "none" });
  videoChatWindow_1.prepend(img);
}

export function sendMissedCall() {
  const { id: callee_id } = window.callee;
  const { id: caller_id } = window.caller;
  return Axios.post(
    `http://worldcommunitynetwork.org/dev/call_api.php?type=save_miss_call&caller_id=${caller_id}&calle_id=${callee_id}`
  );
}

export function subscribeVideoBgTracks(localTracks, callBackground) {
  // if (window.localTracks) localTracks = window.localTracks;

  if (localTracks && callBackground)
    localTracks.forEach((track) => {
      if (track.kind === "video") {
        try {
          if (window.virtualProcessor)
            track.removeProcessor(window.virtualProcessor);
          removeBlurBackground();
        } catch (error) {
          console.log("Remove processor error:", error);
        }
        try {
          track.addProcessor(callBackground, {
            inputFrameBufferType: "video",
            outputFrameBufferContextType: "webgl2",
          });
          window.virtualProcessor = callBackground;
          window.virtualProcessorEx = callBackground;
        } catch (error) {
          console.log("Add processor error:", error);
        }
      }
    });
}

export function removeVirtualBackground() {
  if (window.localTracks)
    window.localTracks.forEach((track) => {
      if (track.kind === "video") {
        try {
          if (window.virtualProcessor) {
            window.virtualProcessorEx = window.virtualProcessor;
            track.removeProcessor(window.virtualProcessor);
          }
          window.virtualProcessor = null;
          window.setCallBlurBackgroundOnLoad = null;
        } catch (error) {
          console.log("Virtual background image remove error: ", error);
        }
      }
    });
  else console.log("no vt image to remove");
}
function removeBlurBackground() {
  if (window.callBlurBackground && window.localVideoTrack) {
    window.localTracks.forEach((track) => {
      if (track.kind === "video") {
        try {
          console.log("Virtual blur background removed.");
          track.removeProcessor(window.callBlurBackground);

          window.callBlurBackground = null;
          window.setCallBlurBackgroundOnLoad = null;
        } catch (error) {
          console.log("Virtual background image remove error: ", error);
        }
      }
    });
  }
}
export function setCallBackgroundImage({ bgImageUrl, connectVidCallback }) {
  if (bgImageUrl) {
    if (!isSupported) {
      alert("Virtual background processor is not supported on this browser");
      if (isFunction(connectVidCallback)) connectVidCallback();
      return;
    }

    const img = new Image(),
      vbi = ["", "vacation.jpg", "living_room.jpg", "office.jpg"][
        Math.ceil(Math.random() * 3)
      ];

    // let img_src = window.bgAssetUrl + "/assets/backgrounds/" + vbi,
    let img_src = "/backgrounds/" + vbi,
      asset_url = window?.bgAssetUrl;

    if (!asset_url) {
      img_src = bgImageUrl;
      asset_url = window?.assetUrl || Cookie("asset_url").get();
    }

    img.src = img_src + "?shuffle=" + Math.ceil(Math.random() * 1000);

    const vturl = asset_url.split("/");
    asset_url = vturl.filter((v) => v !== "video-chat").join("/");

    img.onload = async () => {
      let callBackground;
      try {
        callBackground = new VirtualBackgroundProcessor({
          assetsPath: asset_url + "/vtjs/assets",
          pipeline: Pipeline.WebGL2,
          debounce: true,
          backgroundImage: img,
          maskBlurRadius: 20,
        });

        await callBackground.loadModel();

        window.callVirtualBackground = callBackground;

        if (isFunction(connectVidCallback)) connectVidCallback(callBackground);
      } catch (e) {
        console.error("Virtual background processor Error ~: ", e);
        window.callVirtualBackground = null;
        if (isFunction(connectVidCallback)) connectVidCallback();
      }
    };
    img.onerror = (error) => {
      if (isFunction(connectVidCallback)) connectVidCallback();
      console.log("Image load error:", error);
    };
  }
}

export async function setCallBackgroundBlurEffect({ isBlur = false }) {
  removeBlurBackground();

  if (isBlur) {
    if (!isSupported) {
      alert("Blur background processor is not supported on this browser!");
      return;
    }
    try {
      let asset_url = window.bgAssetUrl;

      if (!asset_url) asset_url = Cookie("asset_url").get();

      const blurBackground = new GaussianBlurBackgroundProcessor({
        assetsPath: asset_url + "/assets",
        blurFilterRadius: 20,
        maskBlurRadius: 5,
        pipeline: Pipeline.Canvas2D,
      });

      await blurBackground.loadModel();

      callBackgroundBlurEffectLoad(blurBackground);

      window.callBlurBackground = blurBackground;
    } catch (e) {
      console.log("", e);
    }
  } else {
    subscribeVideoBgTracks(window.localTracks, window.virtualProcessorEx);
    //   removeBlurBackground();
  }
}

function callBackgroundBlurEffectLoad(blurBackground) {
  //
  if (window.localTracks && blurBackground) {
    window.localTracks.forEach((track) => {
      if (track.kind === "video") {
        try {
          removeVirtualBackground();
          track.addProcessor(blurBackground);
        } catch (error) {
          console.log("Virtual background image remove error: ", error);
        }
      }
    });
  } else window.setCallBlurBackgroundOnLoad = blurBackground;
}

/*********************
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 * ********************/

let users = { caller: "", callee: "" };
if (Cookie("users").has()) {
  try {
    users = JSON.parse(Cookie("users").get());
  } catch (e) {}
}
let _VIDEO_CALL_USERS_PLACEHOLDER = {
  // user 1 is the auth user. meaning the user placing the call
  caller: {
    id: 1,
    avatar:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    name: users?.caller || "John Dumelo",
  },
  // user 2 is the auth user. meaning the user placing the call
  callee: {
    id: 1,
    name: users?.callee || "Nicolas Horn",
    avatar:
      "https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
  },
};

if (typeof window.VIDEO_CALL_USERS != "undefined")
  _VIDEO_CALL_USERS_PLACEHOLDER = window.VIDEO_CALL_USERS;
// Object Schema
export const VIDEO_CALL_USERS = _VIDEO_CALL_USERS_PLACEHOLDER;
