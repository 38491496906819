import React, { useState } from "react";
import { createLocalAudioTrack, createLocalTracks } from "twilio-video";
import { assetURL } from "../../custom/js/func";
import { options } from "../functions/custom";
import CallIcon from "./CallIcon";
import { CallBackgroundSelect } from "./SelectCallBgImage";
import {
  setCallBackgroundBlurEffect,
  subscribeVideoBgTracks,
} from "../functions/video-connect";
import AppContext from "../AppContextProvider";
import { useContext } from "react";

export default function VideoCallStream({
  onMuteAudio,
  isVideoConnected,
  onMuteVideo,
  onEndCall,
}) {
  return (
    <div
      className={"call-box incoming-box " + (!isVideoConnected ? "d-none" : "")}
    >
      <div className="call-wrapper">
        <div className="call-inner">
          <div className="video-dishplay vid-100" id="video-dishplay"></div>
          <div className="call-items">
            <a
              data-bs-dismiss="modal-"
              className="cursor-pointer"
              onClick={onMuteAudio}
            >
              <img src="/mute.png" width={50} />
              <br /> Mute
            </a>
            <a
              data-bs-dismiss="modal"
              className="cursor-pointer"
              onClick={onEndCall}
            >
              <img src="/end.png" width={50} />
              <br /> END
            </a>
            <a
              className="cursor-pointer"
              data-bs-dismiss="modal"
              onClick={onMuteVideo}
            >
              <img src="/no-video.png" width={50} />
              <br /> Video Off
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export function MultipleVideoCallStream({
  // onMuteAudio,
  isVideoConnected,
  // onMuteVideo,
  onEndCall,
  ConnectedRoom,
  localTracks: { localTracks, localAudioTrack, localVideoTrack },
}) {
  const [ToggleMute, setToggleMute] = useState({ video: false, audio: false });
  const { IsBlur, setIsBlur } = useContext(AppContext);
  const video_display_2 = window.$("#video-display-2");

  function _setToggleMute(data = ToggleMute) {
    setToggleMute((s) => ({ ...s, ...data }));
  }

  function toggleAudioMute() {
    if (!ToggleMute.audio) {
      onMuteAudio(undefined);
    } else onUnMuteAudio(undefined);
    _setToggleMute({ audio: !ToggleMute.audio });
  }

  function toggleVideoOff() {
    if (!ToggleMute.video) {
      onVideoOff(undefined);
    } else onVideoOn(undefined);
    _setToggleMute({ video: !ToggleMute.video });
  }

  function onVideoOn(room = ConnectedRoom) {
    if (localVideoTrack) {
      window.localVideoTrack.enable();
    }

    room.localParticipant.videoTracks.forEach((publication) => {
      publication.track.enable();
    });

    createLocalTracks({ ...options })
      .then((_localTracks) => {
        const videoTrack = _localTracks.find((track) => track.kind == "video");
        const audioTrack = _localTracks.find((track) => track.kind == "audio");
        window.localVideoTrack = videoTrack;
        window.localAudioTrack = audioTrack;
        window.localTracks = _localTracks;
        //
        if (window.callVirtualBackground)
          subscribeVideoBgTracks(_localTracks, window.callVirtualBackground);
        if (window.callBlurBackground && IsBlur)
          setCallBackgroundBlurEffect({ isBlur: true });
        // video_display_2.html(_localVideoTrack.attach());

        return room.localParticipant.publishTracks([audioTrack, videoTrack]);
        // return room.localParticipant.publishTrack(_localVideoTrack);
      })
      .then((publication) => {
        video_display_2.html(window.localVideoTrack.attach());
        video_display_2.find("video").css({ width: "100%", height: "100%" });
        console.log("Successfully unmuted your video:", publication);
      });
  }

  function onUnMuteAudio(room = ConnectedRoom) {
    // console.log("unmuted: ", room, room?.localParticipant);

    if (localAudioTrack) window.localAudioTrack.enable();
    // room.localParticipant.audioTracks.forEach((publication) => {
    //   publication.track.enable();
    // });

    // createLocalAudioTrack({ ...options })
    //   .then((_localTracks) => {
    //     const _localAudioTrack = _localTracks;
    //     window.localAudioTrack = _localAudioTrack;

    //     return room.localParticipant.publishTrack(_localAudioTrack);
    //   })
    //   .then(() => {
    //     console.log("Local audio track published successfully");
    //   })
    //   .catch((error) => {
    //     console.error("Failed to publish local audio track:", error);
    //   });
  }

  function onVideoOff(room = ConnectedRoom) {
    if (localVideoTrack) {
      window.localVideoTrack.disable();
    }

    room.localParticipant.videoTracks.forEach((publication) => {
      publication.track.disable();
      publication.track.stop();
      publication.unpublish();
    });
  }

  function onEndVideo() {
    if (localVideoTrack) {
      window.localVideoTrack.stop();
    }
    onMuteAudio();
    onVideoOff();
    onEndCall();
  }

  function onMuteAudio(room = ConnectedRoom) {
    // return;
    if (window.localAudioTrack) window.localAudioTrack.disable();
    // room.localParticipant.audioTracks.forEach((publication) => {
    //   publication.track.disable();
    // });
  }

  return (
    <div
      className={"call-box incoming-box " + (!isVideoConnected ? "d-none" : "")}
    >
      <div className="row px-3 vid-wrapper">
        <div className="col-lg-12 pe-lg-7">
          {/* <div className="col-lg-12 pe-lg-7"> */}
          <VideoWrapper />
        </div>
        {/* <div className="col-lg-6 ps-lg-7"> */}
        <div className="col-lg-12 ps-lg-7">
          <div className="d-flex justify-content-center">
            <VideoWrapper
              videoDisplayClass={"video-dishplay-caller"}
              videoId="video-display-2"
            />
            {/* <div className="d-flex items-center position-relative">
              <div className="position-absolute blurCheckboxWrapper w100">
                <CheckBox
                  onChange={(isBlur) => {
                    setIsBlur(isBlur);
                    setCallBackgroundBlurEffect({ isBlur });
                  }}
                  id="blurEffect-toggle"
                >
                  {" "}
                  Blur Background
                </CheckBox>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="call-items" style={{ marginTop: 30 }}>
            <div className="d-flex-wrap " style={{ flexDirection: "column" }}>
              <CallBackgroundSelect />
              <CheckBox
                style={{
                  display: "block",
                  padding: 10,
                  paddingLeft: 12,
                }}
                onChange={(isBlur) => {
                  setIsBlur(isBlur);
                  setCallBackgroundBlurEffect({ isBlur });
                }}
                id="blurEffect-toggle"
              >
                {" "}
                Blur Background
              </CheckBox>
            </div>

            <a
              data-bs-dismiss="modal-"
              className="cursor-pointer"
              onClick={toggleAudioMute}
            >
              <CallIcon
                color={ToggleMute.audio ? "danger" : ""}
                // icon={ToggleMute.audio ? "microphone-slash" : "microphone"}
              >
                {ToggleMute.audio ? "Unmute Call" : "Mute Call"}
              </CallIcon>
              {/* <img src={assetURL("/mute.png")} width={50} /> */}
              {/* <br / >*/}
            </a>

            <a
              className="cursor-pointer"
              data-bs-dismiss="modal"
              onClick={toggleVideoOff}
            >
              <CallIcon
                color={ToggleMute.video ? "danger" : "info"}
                icon={ToggleMute.video ? "video-slash" : "video"}
                children={ToggleMute.video ? "Turn On Video" : "Turn Off Video"}
                // color="info"
                // icon="video-slash"
              />
              {/* <img src={assetURL("/no-video.png")} width={50} /> */}
              {/* <br /> {ToggleMute.video ? "Turn On Video" : "Turn Off Video"} */}
            </a>
            <a
              data-bs-dismiss="modal"
              className="cursor-pointer"
              onClick={onEndVideo}
            >
              <CallIcon color="danger" icon="times" children={"End Call"} />
              {/* <img src={assetURL("/end.png")} width={50} /> */}
              {/* <br /> End Call */}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

const VideoWrapper = ({
  wrapperClassName,
  videoDisplayClass = "video-dishplay",
  videoId = "video-display-1",
  children,
}) => (
  <div className={"call-box incoming-box " + wrapperClassName}>
    <div className="call-wrapper">
      <div className={"call-inner "}>
        <div className={videoDisplayClass} id={videoId}>
          <img src={assetURL("/video-dishplay.png")} width="100%" />
        </div>
      </div>
    </div>
    {children}
  </div>
);

function CheckBox({ onChange, style = {}, id, children }) {
  const [checked, setChecked] = useState(false);
  // const checkboxElm = document.getElementById(id);
  return (
    <label
      htmlFor={id}
      style={{ display: "inline-block", padding: 5, ...style }}
    >
      <input
        onChange={(e) => {
          if (typeof onChange == "function") onChange(e.target.checked);
          setChecked(e.target.checked);
        }}
        type="checkbox"
        id={id}
        checked={checked}
      />
      {children}
    </label>
  );
}
