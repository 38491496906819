import React, { useEffect } from "react";
import ShowCalling from "./components/ShowCalling";
import { MultipleVideoCallStream } from "./components/VideoCallStream";
import AddNewUser from "./components/AddNewUser";
import AcceptedCall from "./components/AcceptedCall";
import CallFailed from "./components/CallFailed";
import { assetURL } from "../custom/js/func";
import { useContext } from "react";
import AppContext from "./AppContextProvider";
import { modalClose } from "./functions/custom";
import Cookie from "../custom/js/Cookies";
import SelectCallBgImage from "./components/SelectCallBgImage";
import { sendMissedCall } from "./functions/video-connect";

export default function VideoCallContainer({ data }) {
  const {
    // states
    VideoConnected,
    ConnectedRoom,
    //
    onEndCall,
    onRejectCall,
    onAcceptCall,
    CallFailedMessage,
    generateAccessToken,
    Users,
    setUsers,
    getVirtualIimages,
  } = useContext(AppContext);

  let callee;
  try {
    callee = JSON.parse(Cookie("callee").get());
  } catch (e) {}

  // windows
  const { localTracks, localAudioTrack, localVideoTrack } = window;

  useEffect(() => {
    // getVirtualIimages();
  }, []);

  return (
    <div>
      {/* <button data-toggle="modal" data-target="#add_user">
        Add User
      </button> */}
      <ShowCalling
        modalDialogStyle={
          VideoConnected ? { maxWidth: 1050, width: "100%" } : {}
        }
        lineBreakNum={VideoConnected ? 1 : 2}
        {...Users}
        isVideoConnected={VideoConnected}
        onEndCall={() => {
          sendMissedCall();
          onEndCall();
        }}
      >
        <MultipleVideoCallStream
          localTracks={{ localTracks, localAudioTrack, localVideoTrack }}
          isVideoConnected={VideoConnected}
          onEndCall={onEndCall}
          ConnectedRoom={window.$_room || ConnectedRoom}
        />
      </ShowCalling>
      <AcceptedCall
        onRejectCall={onRejectCall}
        onAcceptCall={onAcceptCall}
        {...Users}
      />
      <AddNewUser />
      <SelectCallBgImage
        onCantinueCall={(callBackgroundImage) => {
          // console.log("Re Dial: ", window.callee);
          generateAccessToken(window.callee || callee, callBackgroundImage);
        }}
      />
      <SelectCallBgImage
        id="acceptCallBgSelect"
        onCantinueCall={(callBackgroundImage) => {
          onAcceptCall(callBackgroundImage);
        }}
      />

      <CallFailed
        message={CallFailedMessage}
        onCall={() => {
          // console.log("Re Dial: ", window.conn, callee);
          generateAccessToken(window.conn.callee || callee);
        }}
        onCancel={modalClose}
      />
      <audio id="ringingSound" src={assetURL("/v-ringing-tone.mp3")} loop />
      <audio id="incomingSound" src={assetURL("/v-ringing-tone.mp3")} loop />
      {/* <audio id="ringingSound" src={assetURL("/phone-calling.mp3")} loop /> */}
      {/* <audio id="incomingSound" src={assetURL("/waiting-ringtone.wav")} loop /> */}
    </div>
  );
}
