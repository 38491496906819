import React from "react";

export default function ShowModal({
  id = "myModal2",
  children,
  modalBodyClass = "modal-body",
  modalContentClassName,
  modalDialogClass = "",
  modalDialogStyle = {},
  onClose,
  dismissable,
  lineBreakNum = 2,
}) {
  // React.useEffect(() => {
  //   console.log(window.$);
  //   if (typeof $ != "undefined")
  //     window.$("#" + id).modal({
  //       backdrop: "static",
  //       keyboard: false,
  //     });
  // }, []);

  return (
    <div>
      <div
        className="modal fade md-z-index"
        id={id}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myModalLabel"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className={"modal-dialog modal-dialog-centered " + modalDialogClass}
          style={modalDialogStyle}
          role="document"
        >
          <div className={"modal-content " + modalContentClassName}>
            {[...Array(lineBreakNum)].map(() => (
              <br />
            ))}

            <div
              className={
                "modal-header border-0 " + (dismissable ? "" : "d-none")
              }
            >
              <button
                onClick={onClose}
                type="button"
                className="close p-2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  className="display-3"
                  style={{ fontSize: 22 }}
                >
                  ×
                </span>
              </button>
            </div>
            <div className={modalBodyClass}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
