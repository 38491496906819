import Axios from "axios";

const baseURL =
  document
    .getElementById("video-call-container")
    ?.getAttribute("data-chat-url") ||
  // "https://2831-197-211-58-35.ngrok-free.app" ||
  "http://127.0.0.1:8000";

// console.log(baseURL);
window.baseURL = baseURL;

export const Http = Axios.create({
  baseURL: baseURL + "/api/",
  //baseURL: "http://172.20.10.4:8001/api/",
  // responseType: "json",
  headers: { "X-Requested-With": "XMLHttpRequest", responseType: "json" },
});
