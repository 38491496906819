import React from "react";
import ShowModal from "./ShowModal";
import { CallButton } from "./CallIcon";
import { CallBackgroundSelect } from "./SelectCallBgImage";
// import { VIDEO_CALL_USERS } from "../functions/video-connect";

export default function ShowCalling({
  modalDialogStyle,
  isVideoConnected,
  onEndCall,
  children,
  caller,
  callee,
  ...otherProps
}) {
  // const { caller, callee } = VIDEO_CALL_USERS;

  return (
    <ShowModal
      onClose={onEndCall}
      id="myModal2"
      modalBodyClass="voice_body"
      modalDialogStyle={modalDialogStyle}
      {...otherProps}
    >
      {children}
      {!isVideoConnected && (
        <div className="call-box incoming-box">
          <div className="call-wrapper">
            <div className="call-inner calling-form">
              <h4>Calling {callee.name}...</h4>
              <br />
              <div
                className="call-user"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="call-avatar">
                  <img alt="User Image" src={caller.avatar} />
                </div>
                <div className="arrow">
                  <span />
                  <span />
                  <span />
                </div>
                <div className="call-avatar">
                  <img alt="User Image" src={callee.avatar} />
                </div>
              </div>
              <div className="call-items text-center">
                <CallBackgroundSelect />
                {/* <CallButton
                  onClick={}

                  color="info"
                  icon=" fa-solid fa-users-viewfinder"
                /> */}
                <CallButton
                  onClick={onEndCall}
                  color="danger"
                  icon="times"
                  children={"End Call"}
                />
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      )}
    </ShowModal>
  );
}
